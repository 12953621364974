<template>
    <div class="login_content  fadeIn fourth">
        <div class="login_panel">
            <div class="login_logo">
                <img src="../../assets/images/logo/betstoa/logo.png" alt="" style="width: 230px">
            </div>
            <div class="login_items login_items_rel" v-if="$store.state.isMaintaining == false">
                <div class="ipt">
                    <input type="text" v-model="user.username" :placeholder="i18nConst.USER['USER_NAME'][$store.state.country]" @keyup.enter="doLogin"/>
                </div>
                <div class="ipt">
                    <input type="password" v-model="user.passwd" :placeholder="i18nConst.USER['PASSWORD'][$store.state.country]" @keyup.enter="doLogin">
                </div>
                <div class="btns">
                    <router-link tag="button" to="" @click.native="doLogin" class="login">{{i18nConst.COMMON['LOGIN'][$store.state.country]}}</router-link>
                    <router-link class="register" tag="button" to="" @click.native="showRegisterPanel">{{i18nConst.COMMON['REGISTE'][$store.state.country]}}</router-link>
                </div>
                <div class="err" v-show="errmessage != null">
                    <i class="fa fa-warning"></i> {{errmessage}}
                </div>
            </div>
            <div class="login_items login_items_rel" v-if="$store.state.isMaintaining == true">
                <div v-html="$store.state.maintainingMessage">

                </div>
            </div>
        </div>



    </div>


</template>

<script>
    import {getPopupList, getSiteStatus, getUserInfo, login, logout} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../../store/mutation-types";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "login",
        components: {
            SportsLeftBarComp, RightBarBannerComp, FootComp, RightBarComp, LeftBarComp, TopbarComp
        },
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                },
                isShowMessage: false,
                content: '',
                errmessage: null,
                popup1: null,
                popup2: null,
                i18nConst:i18nConst,
            }
        },
        methods: {
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            doLogin() {

                this.$store.commit(RECEIVE_SHOW_LOADING);
                login(this.user).then(res => {
                    if (res.data.success) {
                        this.errmessage = null
                        getUserInfo().then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                            this.$store.state.isLogin = true;
                            this.$store.state.userInfo = res.data.data
                            //this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            //window.sessionStorage.setItem("isLogin", 'true');
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$store.commit(RECEIVE_HIDE_LOADING);
                        // this.$swal({
                        //     title: res.data.msg,
                        //     type: 'error',
                        //     showCancelButton: false,
                        //     showConfirmButton: true
                        // })
                        this.errmessage = res.data.msg;
                    }
                })
            },
            donotlogin() {

            },
            close24(item) {
                this.$set(item, 'isShow', false)
                this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
            },
            close(item) {
                //this.$store.state.popups.push(item.id)
                this.$set(item, 'isShow', false)
            },
            getPopList() {
                getPopupList(sportsConst.POPUP_PLATFORM_LOGIN).then(res => {
                    if (res.data.success) {
                        this.plist = res.data.data
                        this.plist.forEach(popup => {
                            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
                            this.$set(popup, 'isShow', cookiePopupStatus == null)

                        })
                        if (this.plist.length == 1) {
                            this.popup1 = this.plist[0];
                        }
                        if (this.plist.length == 2) {
                            this.popup1 = this.plist[0];
                            this.popup2 = this.plist[1];
                        }
                    }
                })
            }

        },
        created() {
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    this.$store.state.isShowLoginPanel = true;
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            this.getPopList()
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");

    .login_content {
        width: 100%;
        height: 100vh;
        background: #09090a url("../../assets/images/login/betstoa/login-bg.jpg") no-repeat center center;
    }

    .login_panel {
        width: 400px;
        height: 430px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        z-index: 10;
        border-radius: 15px;
        background: rgba(8,45,115,.9);
        border: 0px solid #ffffff;
        border-image: linear-gradient(to right, #ffffff, #ffffff) 1;
        box-shadow: 0 0 20px #ffffff;
        padding: 10px;
        box-sizing: border-box;
    }

    .login_panel .login_logo {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%) scale(1);
    }

    .login_items_rel {
        z-index: 2;
        padding-top: 10px;
    }

    .login_items {
        width: 100%;
        height: 240px;
        border-radius: 10px;
        position: absolute;
        top: 130px;
        left: 50%;
        -webkit-transform: translateX(-50%) scale(1);
        transform: translateX(-50%) scale(1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .ipt {
        width: 80%;
        height: 50px;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
    }

    .ipt input {
        width: 100%;
        height: 48px;
        border-radius: 1px;
        border: 1px solid #ffffff;
        background-color: #335185;
        --box-shadow: inset 2px 3px 13px rgba(206, 248, 60, 0.5);
        text-align: center;
        color: #ffffff;

    }

    .btns {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        flex-wrap: wrap;
    }


    .btns button {
        width: 80%;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: bold;
        margin: 5px;
        border-radius: 5px;

    }

    .btns .login {
        --background-color: #ffe14f;
        background: linear-gradient(180deg,#67717a,#cec9c5) !important;
        color: #ffffff;
    }

    .btns .register {
        --background-color: #fa9841;
        background: linear-gradient(180deg,#8f7042,#e9b257) !important;
        color: #ffffff;
    }

    .fadeIn {
        opacity: 0;
        -webkit-animation: fadeIn ease-in 1;
        -moz-animation: fadeIn ease-in 1;
        animation: fadeIn ease-in 1;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 0.1s;
        -moz-animation-duration: 0.1s;
        animation-duration: 0.1s;
    }

    .fadeIn.fourth {
        animation-delay: 0.3s;
        -webkit-animation-name: fourth1;
        animation-name: fourth1;
    }

    @media screen and (max-width: 1024px) {
        .login_content {
            background-size: cover;
        }

        .login_panel {
            width: 370px!important;
            -webkit-transform: translateX(-50%) translateY(-40%) scale(1) !important;
            transform: translateX(-50%) translateY(-40%) scale(1) !important;
        }
    }

    .err {
        color: #ff4d4d;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        animation: sansan 700ms infinite;
        -webkit-animation: sansan 700ms infinite;
    }


    @keyframes fourth1 {
        0% {
            opacity: 0;

        }
        100% {
            opacity: 1;
        }
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }

    .p1 {
        position: absolute;
        top: 30%;
        left: 10%;
        z-index: 100002;
    }

    .p2 {
        position: absolute;
        top: 30%;
        right: 10%;
        z-index: 100001;
    }

    .popup_section {
        background-color: #1e1e1e;
        color: #fff9ff;
        margin: 5px;
        width: 375px;
        --border-image: linear-gradient(to right, #ffd680, #ffd680) 1;
        --box-shadow: 0 0 15px #fee394;
    }

    .popup_section h4 {
        line-height: 20px;
        height: 20px;
        width: 100%;
        font-size: 12px;
        padding: 20px;
        color: #cca4fd;
    }

    .popup_section .btn-close {
        padding: 8px 8px;
        background-color: #f1ae17;
        color: #0c0e0e;
    }

    .pop_box {
        box-sizing: border-box;
    }

    .pop_box h2 span:hover {
        color: #f19f09;
    }

    .popup_section .pfoot {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        line-height: 40px
    }

    .pfoot div {
        width: 45%;
        font-size: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    @media screen and (max-width: 2560px) {
        .popup {
            -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1);

        }
    }

    @media screen and (max-width: 1920px) {
        .popup {
            -webkit-transform: translateX(-50%) translateY(-37%) scale(1);
            transform: translateX(-50%) translateY(-37%) scale(1);

        }
    }

    @media screen and (max-width: 1024px) {

        .p1 {
            top: 10% !important;
            left: 0% !important;
        }

        .p2 {
            top: 10% !important;
            left: 0% !important;
        }

        .popup_section {
            width: 100% !important;
        }
    }
</style>